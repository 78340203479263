<!--
 * @Description: 登录页面
 * @Date: 2019-12-23 12:29:18
 * @LastEditTime: 2020-04-07 15:23:02
 * @LastEditors: 前胡
 -->
<template>
  <div class="bg-div">
     <div id="login">
      <div class="login-box">
        <h1 class="site-title">折一管理系统</h1>
        <div class="form-wrap">
          <a-form :form="form" @submit="handleSubmit">
            <a-form-item :wrapper-col="{ span: 24 }">
              <a-input
                class="login-input"
                placeholder="请输入用户名或手机号"
                v-decorator="[
                  'username',
                  {
                    rules: [
                      { required: true, message: '请输入用户名或手机号' },
                    ],
                    validateFirst: true
                  }
                ]"
              >
                <a-icon slot="prefix" type="mobile" />
              </a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 24 }">
              <a-input
                class="login-input"
                type="password"
                placeholder="请输入密码"
                v-decorator="['password', { rules: [{ required: true, message: '请输入密码' }] }]"
              >
                <a-icon slot="prefix" type="lock" />
              </a-input>
              <!-- <a-row :gutter="16">
                <a-col :span="15">
                  <a-input
                    class="login-input"
                    placeholder="请输入密码"
                    v-decorator="['code', { rules: [{ required: true, message: '请输入验证码' }] }]"
                  >
                    <a-icon slot="prefix" type="lock" />
                  </a-input>
                </a-col>
                <a-col :span="9">
                  <a-button v-if="!countdown" :disabled="getCodeBtnDisable" class="get-phone-code-btn" @click="handleGetPhoneCodeClick">获取验证码</a-button>
                  <a-button v-else class="get-phone-code-btn">{{second}}s</a-button>
                </a-col>
              </a-row>-->
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 24 }">
              <a-button type="primary"  block html-type="submit">登录</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { adminPasswordLogin } from "../service/admin";
// import { CODE_TYPE } from '../const/index'
import { checkPhoneNumber } from "../utils/validator";

const countdownSecond = 60;

export default {
  name: "login",
  data() {
    return {
      form: this.$form.createForm(this),
      second: countdownSecond,
      countdown: false,
      getCodeBtnDisable: false,
      checkPhoneNumber
    };
  },
  async mounted() {
  },
  methods: {
    // 提交登录
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.doLogin(values);
        }
      });
    },
    // 登录请求
    async doLogin(values) {
      const { err, res } = await adminPasswordLogin({
        password: values.password,
        username: values.username
      });
      if (!err) {
        if (res.success) {
          this.$store.commit("login", res.data);
          await this.$store.dispatch("getRights");
          this.$message.success("登录成功");
          this.$router.go({ name: "loading" });
        }
      }
    }
  }
};
</script>
<style lang='less' scoped>
.bg-div {
  background-image: linear-gradient(180deg, #FFEBF1 0%, #FFFFFF 100%);
  width: 100%;
  height: 600px;
  min-width: 1200px;
}
#login {
  position: absolute;
  top: 40px;
  left: calc((100vw - 1200px)/2);
  width: 1200px;
  height: 600px;
  background: url('~@/assets/icon-login-bg.jpg') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  .login-box {
    width: 350px;
    height: 350px;
    margin: 200px 0 0 750px;
    border-radius: 10px;
    box-shadow: 0 2px 14px 0 #5837471f;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: white;
    animation: bounceInDown 1s;
    -webkit-animation: bounceInDown 1s;
    .site-title {
      text-align: center;
      font-size: 26px;
      font-weight: normal;
      color: #000;
      display: block;
      height: 100px;
      line-height: 100px;
      margin: 0;
    }
    .form-wrap {
      padding: 0 30px;
      .ant-btn,
      .ant-input-affix-wrapper,
      .ant-input {
        height: 40px;
      }
      .ant-btn {
        margin-top: 4px;
      }
    /deep/ .ant-input {
        height: 40px;
      }
      /deep/ .ant-form-explain {
        color: #fff;
      }
      .get-phone-code-btn {
        width: 100%;
        padding: 0;
        text-align: center;
      }
      // /deep/ .login-input {
      //   input {
      //     background: transparent;
      //   }
      //   .ant-input-prefix {
      //     color: #fff;
      //   }
      // }
    }
  }
  @-webkit-keyframes bounceInDown {
    0%,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -3000px, 0);
      transform: translate3d(0, -3000px, 0);
    }
    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, 25px, 0);
      transform: translate3d(0, 25px, 0);
    }
    75% {
      -webkit-transform: translate3d(0, -10px, 0);
      transform: translate3d(0, -10px, 0);
    }
    90% {
      -webkit-transform: translate3d(0, 5px, 0);
      transform: translate3d(0, 5px, 0);
    }
    to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }

  @keyframes bounceInDown {
    0%,
    60%,
    75%,
    90%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -3000px, 0);
      transform: translate3d(0, -3000px, 0);
    }
    60% {
      opacity: 1;
      -webkit-transform: translate3d(0, 25px, 0);
      transform: translate3d(0, 25px, 0);
    }
    75% {
      -webkit-transform: translate3d(0, -10px, 0);
      transform: translate3d(0, -10px, 0);
    }
    90% {
      -webkit-transform: translate3d(0, 5px, 0);
      transform: translate3d(0, 5px, 0);
    }
    to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
}
</style>
